<template>
    <v-row>
    <v-col cols="12" class="px-0">
        <div v-if="load">
            <v-img width="100%" class="my-5" :src="'https://directus.stonevibes.de/assets/'+startPageData.artikel[0].titelbild"></v-img>
            <h2 class="px-5 mb-5">{{startPageData.artikel[0].titel}}</h2>
            <div class="px-5 content_area" v-html="startPageData.artikel[0].text"></div>
        </div>
    </v-col>
    </v-row>
</template>

<script>
import {loadStartPageData} from '@/api/api'
export default {
  name: 'Home',
    data: () => ({
        startPageData:{},
        load:false
    }),
    methods: {
        fetchData() {
            loadStartPageData().then(data =>{
                this.startPageData = data;
                this.load = true;
            })
        }
    },
    created() {
        this.fetchData();
    },
    watch: {
       $route: 'fetchData'
    }
}

</script>
